<template>
  <b-dropdown-item
    class="dd"
    :class="{ 'active-bg': $route.name === routeName }"
    :to="{ name: routeName }"
  >
    <slot />
    <span
      v-if="hasTitle"
      class="ml-1 text-capitalize"
      :class="$route.name === routeName ? 'active-link' : 'text-bluelink'"
    >
      {{ title || routeName }}
    </span>
  </b-dropdown-item>
</template>
<script>
export default {
  props: {
    routeName: String,
    title: String,
    hasTitle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style>
.text-bluelink {
  color: #0c1844;
  font-weight: normal;
}

.active-link {
  font-weight: bold;
  color: #ea234a;
  text-decoration: underline;
}
.active-bg {
  background: #ed859a37;
}
</style>
