export default [
  // COMPANY
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/pages/inside/company/HomePage.vue"),
    meta: {
      description:
        "GOLOG offers reliable freight forwarding, warehousing, and logistics solutions for businesses and individuals. Simplify your shipping with our expert services.",
      nav: "transparent",
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/About",
    name: "About",
    component: () => import("@/views/pages/inside/company/AboutPage.vue"),
    meta: {
      title: "About Us",
      description:
        "Learn about GOLOG's mission to provide exceptional logistics solutions. With a focus on reliability and innovation, we're your trusted logistics partner.",
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/News",
    name: "News",
    component: () => import("@/views/pages/inside/company/NewsPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/Careers",
    name: "Careers",
    component: () =>
      import("@/views/pages/inside/company/career/CareersPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/Careers/:id/:job",
    name: "CareerJob",
    props: true,
    component: () => import("@/views/pages/inside/company/career/JobPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  // DOMESTIC
  {
    path: "/Businesstobusiness",
    name: "B2B",
    component: () => import("@/views/pages/inside/domestic/B2bPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/Businesstocustomer",
    name: "B2C",
    component: () => import("@/views/pages/inside/domestic/B2cPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/chartered",
    name: "Chartered",
    component: () => import("@/views/pages/inside/domestic/CharteredPage.vue"),
    meta: {
      description:
        "Explore GOLOG's customized Chartered Truck Services for your cold chain logistics needs. From route planning to temperature control, we deliver efficiency and reliability for bulk transport and urgent deliveries.",
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/Smarttruck",
    name: "SmartTruck",
    component: () => import("@/views/pages/inside/domestic/SmartTruckPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  // INTERNATIONAL
  {
    path: "/AirFreightForwarding",
    name: "AirFreightForwarding",
    component: () =>
      import("@/views/pages/inside/international/AirFreightPage.vue"),
    meta: {
      title: "Air Freight",
      description:
        "GOLOG's Air Freight Forwarding ensures fast, reliable delivery for your international shipments. Experience efficient logistics with our tailored air cargo solutions. Contact us today!",
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/SeaFreightForwarding",
    name: "SeaFreightForwarding",
    component: () =>
      import("@/views/pages/inside/international/SeaFreightPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  // VENTURE
  {
    path: "/InternationalTrading",
    name: "InternationalTrading",
    component: () =>
      import("@/views/pages/inside/venture/InternationalTradingPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  // HELP
  {
    path: "/Contact",
    name: "Contact",
    component: () => import("@/views/pages/inside/help/ContactPage.vue"),
    meta: {
      description:
        "Get in touch with GOLOG for all your logistics needs. From freight forwarding to warehousing, we're here to streamline your shipping solutions.",
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },

  // COMPANY POLICIES
  {
    path: "/RefundPolicy",
    name: "RefundPolicy",
    component: () =>
      import("@/views/pages/inside/company-policies/RefundPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/DataPrivacyPolicy",
    name: "DataPrivacyPolicy",
    component: () =>
      import("@/views/pages/inside/company-policies/DataPrivacyPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/PrivacyTermsPolicy",
    name: "PrivacyTermsPolicy",
    component: () =>
      import("@/views/pages/inside/company-policies/PrivacyTermsPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/RulesConductPolicy",
    name: "RulesConductPolicy",
    component: () =>
      import("@/views/pages/inside/company-policies/RulesConductPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  // MAIN
  {
    path: "/RHDC",
    name: "RHDC",
    component: () => import("@/views/pages/inside/main/RHDCPage.vue"),
    meta: {
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/Quotation",
    name: "Quotation",
    component: () => import("@/views/pages/inside/main/RequestQuotation.vue"),
    meta: {
      title: "Request a Quote",
      description:
        "Get a customized quote for your logistics needs with GOLOG. From freight forwarding to warehousing, we provide reliable solutions tailored to you. Request your quote today!",
      layout: "main",
      resource: "Auth",
      redirectIfLoggedIn: false,
    },
  },
];
