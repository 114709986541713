<template>
  <b-navbar
    id="navigation-bar"
    toggleable="md"
    class="pl-md-5 pr-md-2 transparent"
    :class="$route.meta.nav === 'transparent' ? 'nav-transparent' : ''"
  >
    <div />
    <div class="d-flex align-items-center">
      <NavLogin class="d-md-none" :is-sm-home="isSmHome" />
      <b-navbar-toggle class="border-0 text-white" target="">
        <a @click="isShowNav = !isShowNav">
          <feather-icon
            icon="MenuIcon"
            size="35"
            :class="isSmHome ? 'text-white' : 'text-primary'"
          />
        </a>
      </b-navbar-toggle>
    </div>

    <!-- MD+ NAVIGATION-->
    <b-collapse is-nav class="">
      <b-navbar-brand :to="{ name: 'Home' }">
        <img
          src="@/assets/images/logo/logo-red.png"
          alt=""
          contain
          height="45px"
          width="65px"
        />
      </b-navbar-brand>
      <b-navbar-nav>
        <!-- COMPANY DROPDOWN -->
        <b-nav-item-dropdown left class="mr-1">
          <template #text>
            <span
              :class="
                $route.meta.nav === 'transparent'
                  ? 'nav-text-white'
                  : 'nav-text-dark'
              "
            >
              Company
            </span>
          </template>
          <nav-router route-name="About">
            <img src="@/assets/images/icons/main/about.svg" height="20" />
          </nav-router>
          <nav-router route-name="Careers">
            <img src="@/assets/images/icons/main/career.svg" height="20" />
          </nav-router>
          <!-- <nav-router route-name="" title="Terms & Conditions">
            <img src="@/assets/images/icons/main/terms.svg" height="20" />
          </nav-router> -->
        </b-nav-item-dropdown>
        <!-- SERVICES DROPDOWN -->
        <b-nav-item-dropdown left class="mr-1">
          <template #text>
            <span
              :class="
                $route.meta.nav === 'transparent'
                  ? 'nav-text-white'
                  : 'nav-text-dark'
              "
            >
              Services
            </span>
          </template>
          <div class="d-flex flex-wrap service-dd">
            <!-- DOMESTICS -->
            <div class="box">
              <label class="ml-1 title">Domestics</label>
              <nav-router route-name="B2C">
                <img src="@/assets/images/icons/main/b2c.svg" height="20" />
              </nav-router>
              <nav-router route-name="B2B" title="Business">
                <img
                  src="@/assets/images/icons/main/business.svg"
                  height="20"
                />
              </nav-router>
              <nav-router route-name="Chartered">
                <img src="@/assets/images/icons/main/truck.svg" height="20" />
              </nav-router>
              <nav-router route-name="SmartTruck" title="SMART TRUCK">
                <img src="@/assets/images/icons/main/truck2.svg" height="20" />
              </nav-router>
            </div>
            <!-- INTERNATIONAL -->
            <div class="box">
              <label class="ml-1 title">International</label>
              <nav-router
                route-name="AirFreightForwarding"
                title="Air Freight Forwarding"
              >
                <img
                  src="@/assets/images/icons/main/airplane.svg"
                  height="20"
                />
              </nav-router>
              <nav-router
                route-name="SeaFreightForwarding"
                title="Sea Freight Forwarding"
              >
                <img src="@/assets/images/icons/main/ship.svg" height="20" />
              </nav-router>
            </div>
            <!-- VENTURE -->
            <div class="box">
              <label class="ml-1 title">Venture</label>
              <nav-router
                route-name="InternationalTrading"
                title="International Trading"
              >
                <img
                  src="@/assets/images/icons/main/international.svg"
                  height="20"
                />
              </nav-router>
            </div>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item class="mr-1" :to="{ name: 'RHDC' }">
          <span
            :class="
              $route.meta.nav === 'transparent'
                ? 'nav-text-white'
                : 'nav-text-dark'
            "
          >
            Smart RHDC
          </span>
        </b-nav-item>
        <b-nav-item class="mr-1" :to="{ name: 'News' }">
          <span
            :class="
              $route.meta.nav === 'transparent'
                ? 'nav-text-white'
                : 'nav-text-dark'
            "
          >
            News
          </span>
        </b-nav-item>
        <b-nav-item :to="{ name: 'Contact' }">
          <span
            :class="
              $route.meta.nav === 'transparent'
                ? 'nav-text-white'
                : 'nav-text-dark'
            "
          >
            Contact
          </span>
        </b-nav-item>
      </b-navbar-nav>

      <!-- BUTTON & PROFILE -->
      <b-navbar-nav class="ml-auto">
        <router-link class="btn-sm bg-rqt mr-1" :to="{ name: 'Quotation' }">
          Request a Quotation
        </router-link>
        <!-- LOG IN BUTTON -->
        <NavLogin />
      </b-navbar-nav>
    </b-collapse>

    <!-- MOBILE NAV-->
    <div v-show="isShowNav" id="nav-collapse" class="smNav d-md-none">
      <div class="d-flex justify-content-end">
        <a @click="isShowNav = !isShowNav">
          <FeatherIcon icon="XIcon" size="35" class="text-primary" />
        </a>
      </div>
      <div class="d-center">
        <div class="w-fit-content">
          <router-link
            :to="{ name: 'Home' }"
            class="d-center mb-2"
            @click.native="closeNav"
          >
            <img
              src="@/assets/images/logo/logo-red.png"
              alt=""
              contain
              height="45px"
              width="65px"
            />
          </router-link>
          <div>
            <a
              class="nav-text-dark text-center"
              @click.prevent="showCompany = !showCompany"
            >
              <h4 class="nav-text-dark">
                Company
                <i v-if="showCompany" class="fas">&#xf077;</i>
                <i v-else class="fas">&#xf078;</i>
              </h4>
            </a>

            <div v-show="showCompany" class="text-center">
              <nav-router
                route-name="About"
                class="dd"
                @click.native="closeNav"
              >
                <img src="@/assets/images/icons/main/about.svg" height="20" />
              </nav-router>
              <nav-router
                route-name="Careers"
                class="dd"
                @click.native="closeNav"
              >
                <img src="@/assets/images/icons/main/career.svg" height="20" />
              </nav-router>
            </div>
          </div>
          <div>
            <a @click.prevent="showServices = !showServices">
              <h4 class="nav-text-dark">
                Services
                <i v-if="showServices" class="fas">&#xf077;</i>
                <i v-else class="fas">&#xf078;</i>
              </h4>
            </a>
            <div v-show="showServices" class="text-center">
              <div class="d-flex flex-wrap service-dd text-center">
                <div>
                  <!-- DOMESTICS -->
                  <div class="box text-center">
                    <label class="ml-1 title">Domestics</label>
                    <nav-router route-name="B2C" @click.native="closeNav">
                      <img
                        src="@/assets/images/icons/main/b2c.svg"
                        height="20"
                      />
                    </nav-router>
                    <nav-router
                      route-name="B2B"
                      title="Business"
                      @click.native="closeNav"
                    >
                      <img
                        src="@/assets/images/icons/main/business.svg"
                        height="20"
                      />
                    </nav-router>
                    <nav-router route-name="Chartered" @click.native="closeNav">
                      <img
                        src="@/assets/images/icons/main/truck.svg"
                        height="20"
                      />
                    </nav-router>
                    <nav-router
                      route-name="SmartTruck"
                      title="SMART TRUCK"
                      @click.native="closeNav"
                    >
                      <img
                        src="@/assets/images/icons/main/truck2.svg"
                        height="20"
                      />
                    </nav-router>
                  </div>
                  <!-- INTERNATIONAL -->
                  <div class="box">
                    <label class="ml-1 title">International</label>
                    <nav-router
                      route-name="AirFreightForwarding"
                      title="Air Freight Forwarding"
                      @click.native="closeNav"
                    >
                      <img
                        src="@/assets/images/icons/main/airplane.svg"
                        height="20"
                      />
                    </nav-router>
                    <nav-router
                      route-name="SeaFreightForwarding"
                      title="Sea Freight Forwarding"
                      @click.native="closeNav"
                    >
                      <img
                        src="@/assets/images/icons/main/ship.svg"
                        height="20"
                      />
                    </nav-router>
                  </div>
                  <!-- VENTURE -->
                  <div class="box">
                    <label class="ml-1 title">Venture</label>
                    <nav-router
                      route-name="InternationalTrading"
                      title="International Trading"
                      @click.native="closeNav"
                    >
                      <img
                        src="@/assets/images/icons/main/international.svg"
                        height="20"
                      />
                    </nav-router>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-nav-item
            class="mr-1 dd text-center"
            :to="{ name: 'RHDC' }"
            @click="closeNav"
          >
            <span class="nav-text-dark"> RHDC </span>
          </b-nav-item>
          <b-nav-item
            class="mr-1 dd text-center"
            :to="{ name: 'News' }"
            @click="closeNav"
          >
            <span class="nav-text-dark"> News </span>
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'Contact' }"
            class="dd text-center"
            @click="closeNav"
          >
            <span class="nav-text-dark"> Contact </span>
          </b-nav-item>
          <div class="d-center">
            <router-link
              class="btn-quotation bg-rqt mt-1"
              :to="{ name: 'Quotation' }"
              @click.native="closeNav"
            >
              Request a Quotation
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </b-navbar>
</template>
<script>
import NavRouter from "../NavRouter.vue";
import NavLogin from "./NavLogin.vue";

export default {
  components: { NavRouter, NavLogin },
  data() {
    return {
      showServices: false,
      showCompany: false,
      isShowNav: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isSmHome() {
      return !!(this.$route.name === "Home" && this.windowWidth < 768);
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    closeNav() {
      this.isShowNav = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-text-white {
  color: white;
  font-weight: 500 !important;
}
.nav-text-dark {
  color: #0c1844;
  font-weight: 500 !important;
}
.smNav {
  position: fixed;
  background-color: white;
  z-index: 1050;
  width: 100%;
  top: 0;
  right: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  color: #0c1844 !important;
  .nav-text-dark {
    font-size: 1.3rem;
    text-align: center;
  }
}
.d-center {
  display: flex;
  justify-content: center;
}

.smooth-dropdown .dropdown-menu {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
}

.smooth-dropdown .dropdown-menu.show {
  display: block;
  opacity: 1;
  transform: translateY(0);
}
.btn-quotation {
  padding: 15px 10px;
  width: 170px !important;
  height: 25px;
  text-align: center !important;
  border-radius: 5px;
}
</style>
