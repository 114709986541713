import { render, staticRenderFns } from "./MyFooter.vue?vue&type=template&id=23dd7026&scoped=true&"
var script = {}
import style0 from "./MyFooter.vue?vue&type=style&index=0&id=23dd7026&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23dd7026",
  null
  
)

export default component.exports