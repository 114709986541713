export default [
  {
    path: "/AbuDhabi",
    name: "AbuDhabi",
    component: () => import("@/views/pages/inside/UnderConstruction.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/InvestorDetails",
    name: "InvestorDetails",
    component: () => import("@/views/pages/inside/InvestorDetails.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/moa-agreement",
    name: "MOA Agreement",
    component: () => import("@/views/pages/inside/MOAAgreement.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/pages/inside/Landing.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/pages/miscellaneous/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "*",
    redirect: "error-404",
    resource: "Auth",
  },
];
