<template>
  <b-nav-item-dropdown
    right
    class="dd no-caret"
  >
    <template #button-content>
      <img
        v-if="isSmHome"
        src="@/assets/images/icons/main/profile-white.svg"
        height="30"
      >
      <img
        v-else
        src="@/assets/images/icons/main/profile.svg"
        height="30"
      >
    </template>
    <b-dropdown-item
      href="https://minidc.golog.my/"
      target="_blank"
    >
      <img
        src="@/assets/images/icons/main/outlet.svg"
        height="20"
      >
      <span class="ml-1 text-bluelink"> Outlet </span>
    </b-dropdown-item>
    <b-dropdown-item
      href="https://minidcdistributor.golog.my/login"
      target="_blank"
    >
      <img
        src="@/assets/images/icons/main/b2c.svg"
        height="20"
      >
      <span class="ml-1 text-bluelink"> B2C/Distributor </span>
    </b-dropdown-item>

    <b-dropdown-item
      href="https://b2bpro.golog.my/login"
      target="_blank"
    >
      <img
        src="@/assets/images/icons/main/business.svg"
        height="20"
      >
      <span class="ml-1 text-bluelink"> B2B </span>
    </b-dropdown-item>
    <b-dropdown-item
      href="https://smart.golog.my/"
      target="_blank"
    >
      <img
        src="@/assets/images/icons/main/truck2.svg"
        height="20"
      >
      <span class="ml-1 text-bluelink"> SMART TRUCK </span>
    </b-dropdown-item>
    <b-dropdown-item
      href="https://www.dhl.com"
      target="_blank"
    >
      <img
        src="@/assets/images/icons/main/dhl.svg"
        height="20"
      >
      <span class="ml-1 text-bluelink"> DHL </span>
      </img>
    </b-dropdown-item></b-nav-item-dropdown>
</template>
<script>
export default {
  props: { isSmHome: Boolean },
}
</script>
<style>
.no-caret .dropdown-toggle::after {
  display: none;
}
.dd {
  list-style-type: none;
}
</style>
