<template>
  <div class="bg-white">
    <my-nav />
    <router-view />
    <my-footer />
  </div>
</template>
<script>
import LayoutFull from "@core/layouts/layout-full/LayoutFull.vue";
import MyNav from "@/components/layout/MyNav.vue";
import MyFooter from "@/components/layout/MyFooter.vue";

export default {
  components: { MyNav, MyFooter, LayoutFull },
};
</script>
